@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  /* background-image: url(assets/bg.jpg); */
  background-color: #000;
}

.ax-alexa {
  top: 50vh;
  height: 140vh;
}

.ax-alexa > h1 {
  top: 15vh;
  line-height: 0;
}

.ax-happy-birthday {
  top: 100vh;
  height: 50vh;
}

.ax-letter {
  padding-top: 200vh;
  padding-bottom: 50vh;
}

.ax-hologram {
  width: 100vw;
  height: 20rem;
}

.ax-omg {
  width: 100vw;
  height: 20rem;
}

.ax-love-explosion {
  height: 40rem;
}

.ax-drumroll {
  width: 100vw;
  height: 20rem;
}

.ax-logo-zoom {
  height: 180vh;
}

.ax-logo {
  width: 50vw;
  height: 20rem;
}

.ax-looking-glass {
  height: 160vh;
  margin-top: -40vh;
}

.ax-features {
  list-style: none;
}

/* .ax-features > li::before {
  content: "\2022";
  color: #fff;
  display: inline-block;
  padding-right: 0.5rem;
} */

video::-internal-media-controls-overlay-cast-button {
  display: none;
}
